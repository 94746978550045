<template>
  <CRow class="form-group mitarbeiter-auswahl-formgroup">
    <CCol sm="12">
      <label :class="formLabelClass">{{formLabel}}</label>
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">
        <v-select
          id="mitarbeiter"
          v-if="!disabled"
          v-model="filterValue"
          placeholder="Bitte wählen Sie einen Mitarbeiter aus ..."
          :options="mitarbeiter"
          :clearable="deleteButton"
          :class="isValidClass(errors)"
          v-on:input="inputEvent"
        >
          <template #no-options>
            Keine Mitarbeiter gefunden.
          </template>
        </v-select>
        <v-select
          v-else
          disabled="disabled"
          v-model="filterValue"
          placeholder="Bitte wählen Sie einen Mitarbeiter aus ..."
          :options="mitarbeiter"
          :clearable="deleteButton"
          :class="isValidClass(errors)"
          v-on:input="inputEvent"
        >
          <template #no-options>
            Keine Mitarbeiter gefunden.
          </template>
        </v-select>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'mitarbeiterAuswahl',
  props: {
    value: [String, Number, Event],
    disabled: {
      default: false,
      type: Boolean
    },
    formLabel: {
      default: 'Mitarbeiter',
      type: String
    },
    formLabelClass: {
      default: 'd-none',
      type: String
    },
    rules: {
      default: 'required',
      type: String
    },
    deleteButton: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      isValid: false,
      filterValue: null,
      mitarbeiterDB: null
    }
  },
  watch: {
    value: function () {
      this.valueToLocal()
    }
  },
  mounted () {
    this.loadMitarbeiter().then((response) => {
      this.valueToLocal()
    })
  },
  computed: {
    mitarbeiter () {
      const options = []
      const self = this
      this._.forEach(this.mitarbeiterDB, function (marbeiter) {
        if (marbeiter.aktiv) {
          const tmp = {
            code: marbeiter.mitarbeiterid,
            label: self.generateLabelName(marbeiter)
          }
          options.push(tmp)
        }
      })
      return options
    }
  },
  methods: {
    valueToLocal: function () {
      this.filterValue = this.value
      if (this.value) {
        this.isValid = true
      }
      // Value nach Mitarbeiter
      const self = this
      if (this.filterValue && this.mitarbeiter) {
        const arrayid = Vue._.findIndex(this.mitarbeiter, function (m) { return m.code === self.filterValue })
        if (arrayid !== -1) {
          this.filterValue = this.mitarbeiter[arrayid]
        }
      }
    },
    inputEvent: function (newValue) {
      if (newValue) {
        this.isValid = true
        this.$emit('input', newValue.code)
      } else {
        // Null, bzw. delete-Button
        this.$emit('input', null)
      }
    },
    generateLabelName: function (mitarbeiter) {
      let result = mitarbeiter.name
      let titel = mitarbeiter.titel
      if (titel === '10') { titel = 'Helfer' }
      if (titel === '20') { titel = 'Programmierer' }
      if (titel === '30') { titel = 'Monteur' }
      if (titel === '40') { titel = 'Obermonteur' }
      if (titel === '50') { titel = 'Kundendiensttechniker' }
      if (titel === '60') { titel = 'Meister' }
      if (titel === '70') { titel = 'Systemtechniker' }
      result = result + ' (' + titel + ')'
      return result
    },
    isValidClass: function (errors) {
      let result = 'form-control'
      if (errors.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    loadMitarbeiter: function () {
      return Vue.axios.get('/mitarbeiter/get')
        .then((response) => {
          this.mitarbeiterDB = response.data
        })
    }
  }
}
</script>

<style lang="scss">
div.mitarbeiter-auswahl-formgroup {
  div.form-control {
    padding: 1px 0 0 1px;
    div.vs__dropdown-toggle {
      border: none;
    }
  }
  div.v-select {
    height: auto;
  }
}
</style>

<template>
  <div id="appvue">
    <router-view></router-view>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style lang="scss">
// Import Main styles for this application,
@import 'assets/scss/style';
</style>

import axios from 'axios'
import router from '../router'
import NProgress from 'nprogress'

export default function interceptorSetup () {
  //      _   _   _ _____ _   _   _   _ _____    _    ____  _____ ____
  //     / \ | | | |_   _| | | | | | | | ____|  / \  |  _ \| ____|  _ \
  //    / _ \| | | | | | | |_| | | |_| |  _|   / _ \ | | | |  _| | |_) |
  //   / ___ \ |_| | | | |  _  | |  _  | |___ / ___ \| |_| | |___|  _ <
  //  /_/   \_\___/  |_| |_| |_| |_| |_|_____/_/   \_\____/|_____|_| \_\
  //
  axios.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  }, (error) => {
    return Promise.reject(error)
  })

  //   _  _    ___  _   _____ ____  ____   ___  ____
  //  | || |  / _ \/ | | ____|  _ \|  _ \ / _ \|  _ \
  //  | || |_| | | | | |  _| | |_) | |_) | | | | |_) |
  //  |__   _| |_| | | | |___|  _ <|  _ <| |_| |  _ <
  //     |_|  \___/|_| |_____|_| \_\_| \_\\___/|_| \_\
  //
  axios.interceptors.response.use(undefined, (error) => {
    return new Promise(() => {
      if (error.response.status === 401) {
        // Wenn man auf der Seite Login ist, nicht noch einmal redirecten
        if (router.currentRoute.name !== 'Login') {
          router.push({ path: '/security/login' })
        }
      }
      throw error
    })
  })

  //   _   _ ____  ____   ___   ____ ____  _____ ____ ____    ____ _____  _    ____ _____
  //  | \ | |  _ \|  _ \ / _ \ / ___|  _ \| ____/ ___/ ___|  / ___|_   _|/ \  |  _ \_   _|
  //  |  \| | |_) | |_) | | | | |  _| |_) |  _| \___ \___ \  \___ \ | | / _ \ | |_) || |
  //  | |\  |  __/|  _ <| |_| | |_| |  _ <| |___ ___) |__) |  ___) || |/ ___ \|  _ < | |
  //  |_| \_|_|   |_| \_\\___/ \____|_| \_\_____|____/____/  |____/ |_/_/   \_\_| \_\|_|
  //
  axios.interceptors.request.use(config => {
    NProgress.start()
    return config
  })

  //   _   _ ____  ____   ___   ____ ____  _____ ____ ____    _____ _   _ ____
  //  | \ | |  _ \|  _ \ / _ \ / ___|  _ \| ____/ ___/ ___|  | ____| \ | |  _ \
  //  |  \| | |_) | |_) | | | | |  _| |_) |  _| \___ \___ \  |  _| |  \| | | | |
  //  | |\  |  __/|  _ <| |_| | |_| |  _ <| |___ ___) |__) | | |___| |\  | |_| |
  //  |_| \_|_|   |_| \_\\___/ \____|_| \_\_____|____/____/  |_____|_| \_|____/
  //
  axios.interceptors.response.use(response => {
    NProgress.done()
    return response
  }, (error) => {
    // Auch wenn ein Error Returned wird, muss NProgress stoppen
    NProgress.done()
    return Promise.reject(error)
  })
}

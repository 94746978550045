import Vue from 'vue'
import store from '../store'

export default {
  userHasRole (roles) {
    if (store.getters['security/hasRole']('ROLE_ADMIN')) {
      return true
    } else {
      let hasRole = false
      Vue._.forEach(roles, function (role) {
        if (store.getters['security/hasRole'](role)) {
          hasRole = true
        }
      })
      return hasRole
    }
  },
  userHasNotRole (roles) {
    let hasnotRole = true
    Vue._.forEach(roles, function (role) {
      if (store.getters['security/hasRole'](role)) {
        hasnotRole = false
      }
    })
    return hasnotRole
  }
}

<template>
  <CRow class="form-group">
    <CCol sm="12">
      <ValidationProvider :rules="rules"
                          immediate v-slot="{ errors }">
        <label v-if="formLabel">{{formLabel}}</label>

        <div class="input-group mb-2">
          <div class="input-group-prepend">
            <span class="input-group-text" id="currency-addon">
              <CIcon name="cil-calculator"/>
            </span>
          </div>

          <input
            type="number"
            :class="calculateClass(errors)"
            aria-describedby="number-addon"
            pattern="^\d*(\.\d{0,2})?$"
            step="0.25"
            min="0"
            v-model="localValue"
            v-on:change="setTwoNumberDecimal()"
            :placeholder="placeholder"
            v-on:input="inputEvent"
            :disabled="disabled"
          />
        </div>

        <em :class="calculateClassHint(errors)">Bitte geben Sie eine gültige Zahl an.</em>

      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'FormCurrency',
  props: {
    formLabel: {
      default: 'Text',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    rules: {
      default: '',
      type: String
    },
    disabled: {
      default: null,
      type: String
    },
    digits: {
      default: 2,
      type: Number
    },
    value: Number
  },
  data () {
    return {
      localValue: 0
    }
  },
  mounted () {
    this.localValue = this.value
  },
  watch: {
    value: function () {
      this.localValue = this.value
    }
  },
  methods: {
    setTwoNumberDecimal: function () {
      this.localValue = parseFloat(this.localValue).toFixed(2)
    },
    inputEvent: function (event) {
      let value = event.target.value
      value = value.replace(',', '.')
      this.$emit('input', value)
    },
    calculateClass: function (error) {
      let result = 'form-control'
      if (error.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    calculateClassHint: function (error) {
      let result = 'error invalid-feedback'
      if (error.length === 0) {
        result = result + ' d-none'
      } else {
        result = result + ' d-block'
      }
      return result
    }
  }
}
</script>

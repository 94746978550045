<template>
  <div class="hopsterimg">
    <div class="thumbnail" v-if="imgthumb">
      <img v-auth-image="imgthumb" class="img-fluid img-clickable" v-on:click="showModal = true"/>
      <CModal
        title="Großansicht Bild"
        color="warning"
        :show.sync="showModal"
      >
        <img v-auth-image="imgfull" class="img-fluid"/>
      </CModal>
    </div>
    <div v-else>
      <img src="/img/placeholder.png" class="img-fluid" width="150" v-on:click="showUpload = true">
    </div>
  </div>
</template>

<script>
export default {
  name: 'component-bild',
  components: {
  },
  props: {
    bild: Object,
    type: String
  },
  data: function () {
    return {
      imgserver: process.env.VUE_APP_MEDIA_BASEURL,
      showModal: false
    }
  },
  computed: {
    imgthumb () {
      let result = null
      if (this.bild) {
        result = this.imgserver + '/api/_/media/bilder/thumb/' + this.bild.bildid
      }
      return result
    },
    imgfull () {
      let result = null
      if (this.bild) {
        result = this.imgserver + '/api/_/media/bilder/medium/' + this.bild.bildid
      }
      return result
    }
  }
}
</script>

<style lang="scss">
div.hopsterimg {
  img {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    &.img-clickable {
      cursor: pointer;
    }
  }
}
</style>

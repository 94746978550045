//    ____ ___  ____  _____ _   _ ___
//   / ___/ _ \|  _ \| ____| | | |_ _|
//  | |  | | | | |_) |  _| | | | || |
//  | |___ |_| |  _ <| |___| |_| || |
//   \____\___/|_| \_\_____|\___/|___|
//
export default {
  namespaced: true,
  state: {
    darkMode: localStorage.getItem('darkMode') || false
  },
  getters: {
    darkMode (state) {
      let darkMode = state.darkMode
      if (typeof darkMode === 'string') {
        darkMode = JSON.parse(darkMode)
      }
      return darkMode
    }
  },
  mutations: {
    TOGGLE_DARKMODE (state, mode) {
      state.darkMode = mode
      localStorage.setItem('darkMode', mode)
    }
  }
}

import Vue from 'vue'
import Vuex from 'vuex'

import PersistentModule from './persistent'
import SecurityModule from './security'
import CoreuiModule from './coreui'
import KundenModule from './kunden'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    persistent: PersistentModule,
    security: SecurityModule,
    coreui: CoreuiModule,
    kunden: KundenModule
  }
})

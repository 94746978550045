<template>
  <div class="imagecomponent" >
    <CRow class="form-group">
      <CCol sm="12">
        <label :class="formLabelClass">{{formLabel}}</label>
      </CCol>
    </CRow>
    <CRow class="form-group">
      <CCol sm="12" class="input">
        <CInputFile
          custom
          name="hopsterImage"
          placeholder="Datei auswählen..."
          accept=".png,.jpg"
          ref="fileupload"
          @change.native="handleFileUpload"
        />
      </CCol>
      <CCol sm="12" class="preview mt-2">
        <img v-if="imgurl" :src="imgurl" class="img-fluid mt-xs-3" />
      </CCol>
    </CRow>
  </div>
</template>

<script>

export default {
  name: 'FormImage',
  data () {
    return {
      uploadfile: ''
    }
  },
  props: {
    formLabel: {
      default: 'Bild',
      type: String
    },
    formLabelClass: {
      default: '',
      type: String
    },
    value: {
      default: '',
      type: [File, String]
    }
  },
  computed: {
    imgurl () {
      if (this.uploadfile) {
        return URL.createObjectURL(this.uploadfile)
      } else {
        return null
      }
    }
  },
  mounted () {
    if (this.value) {
      this.uploadfile = this.value
    } else {
      this.uploadfile = ''
      const el = this.$refs.fileupload.$el.parentNode.querySelector('label.custom-file-label')
      el.innerHTML = 'Datei auswählen...'
    }
  },
  watch: {
    uploadfile: function () {
      this.$emit('input', this.uploadfile)
    },
    value: function () {
      if (!this.value || this.value === '') {
        // Fileinput Empty
        this.$refs.fileupload.value = null
        // Text Löschen
        const el = this.$refs.fileupload.$el.parentNode.querySelector('label.custom-file-label')
        el.innerHTML = 'Datei auswählen...'
        //
        this.uploadfile = ''
      } else {
        this.uploadfile = this.value
        const el = this.$refs.fileupload.$el.parentNode.querySelector('label.custom-file-label')
        el.innerHTML = 'Datei auswählen...'
      }
    }
  },
  methods: {
    handleFileUpload (e) {
      this.uploadfile = e.target.files[0]
      const el = e.target.parentNode.querySelector('label.custom-file-label')
      el.innerHTML = this.uploadfile.name
    }
  }
}
</script>

<style lang="scss">
  img {
    width: 100%;
  }
  .custom-file-input ~ .custom-file-label::after {
    content: "Bild";
  }
  @media (max-width: 575.98px) {
    .mt-xs-3 {
      margin-top: 0.4rem;
    }
  }
</style>

<template>
  <CRow class="form-group">
    <CCol sm="12">
      <ValidationProvider :rules="localRules" immediate v-slot="{ errors }">
        <CInput
          :label="formLabel"
          type="text"
          :placeholder="placeholder"
          :disabled="disabled"
          v-bind:value="value"
          v-on:input="inputEvent"
          :isValid="errors.length ==  0"
        >
          <template #invalid-feedback>
            <em class="error invalid-feedback">Das Feld darf nicht leer sein.</em>
          </template>

          <template #append-content v-if="localRuleSwitch">
            <span v-on:click="toggleRuleSwitch" class="ruleSwitch">
              <CIcon name="cil-paperclip" />
            </span>
          </template>

        </CInput>
      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: 'FormText',
  props: {
    formLabel: {
      default: 'Text',
      type: String
    },
    rules: {
      default: 'required',
      type: String
    },
    placeholder: {
      default: '',
      type: String
    },
    disabled: {
      default: null,
      type: String
    },
    ruleSwitch: {
      default: false,
      type: Boolean
    },
    value: String
  },
  created () {
    this.localRules = this.rules
    this.localRuleSwitch = this.ruleSwitch
  },
  data () {
    return {
      localRules: '',
      localRuleSwitch: false
    }
  },
  methods: {
    inputEvent: function (newValue) {
      this.$emit('input', newValue)
    },
    toggleRuleSwitch: function () {
      this.localRules = ''
      this.localRuleSwitch = false
    }
  }
}
</script>

<style lang="scss">
  span.ruleSwitch {
    cursor: pointer;
  }
</style>

//   _  ___   _ _   _ ____  _____ _   _
//  | |/ / | | | \ | |  _ \| ____| \ | |
//  | ' /| | | |  \| | | | |  _| |  \| |
//  | . \| |_| | |\  | |_| | |___| |\  |
//  |_|\_\\___/|_| \_|____/|_____|_| \_|
//
import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    kunden: []
  },
  getters: {
    kunden: (state) => {
      return state.kunden
    },
    getKundeById: (state) => (kundenid) => {
      if (kundenid) {
        const arrayid = Vue._.findIndex(state.kunden, function (k) { return k.kundenid === kundenid })
        if (arrayid !== -1) {
          return state.kunden[arrayid]
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  mutations: {
    SET_KUNDEN: function (state, kunden) {
      state.kunden = kunden
    }
  },
  actions: {
    REFRESH: (context) => {
      return Vue.axios.get('/kunden/get')
        .then((response) => {
          context.commit('SET_KUNDEN', response.data)
        })
        .catch((error) => {
          return error
        })
    }
  }
}

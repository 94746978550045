//   ___ __  __ ____   ___  ____ _____
//  |_ _|  \/  |  _ \ / _ \|  _ \_   _|
//   | || |\/| | |_) | | | | |_) || |
//   | || |  | |  __/| |_| |  _ < | |
//  |___|_|  |_|_|    \___/|_| \_\|_|
//
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VuejsDialog from 'vuejs-dialog'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import sNotify from 'vue-snotify'
import vSelect from 'vue-select'
import Vue2Filters from 'vue2-filters'
import VueAuthImage from 'vue-auth-image'
import VueRepeater from 'vue-repeater'
import CoreuiVueCharts from '@coreui/vue-chartjs'
import axiosInterceptors from './helpers/axiosInterceptors'
import routingInterceptors from './helpers/routingInterceptors'
import VCalendar from 'v-calendar'
import VueSignaturePad from 'vue-signature-pad'

// Formulare für vue-repeater
import FormularMaterial from './views/Protokoll/components/Formulare/Material'
import FormularArbeitszeit from './views/Protokoll/components/Formulare/Arbeitszeit'

import CoreuiVue from '@coreui/vue'

// VeeValidate
import { ValidationProvider as VeeValidationProvider, ValidationObserver as VeeValidationObserver, extend as VeeExtend } from 'vee-validate'
import { email as VeeEmail, min as VeeMin, required as VeeRequired, confirmed as VeeConfirmed } from 'vee-validate/dist/rules'

import App from './App.vue'
import router from './router'
import store from './store'
import { iconsSet as icons } from './assets/icons/icons.js'

// Mixins
import RoleBasedSecurity from './mixins/RoleBasedSecurity'

// Herbot Inputmask
const VueInputMask = require('vue-inputmask').default

// Moment mit deutscher Locale
const moment = require('moment')
require('moment/locale/de')

//    ____ ___  _   _ _____ ___ ____
//   / ___/ _ \| \ | |  ___|_ _/ ___|
//  | |  | | | |  \| | |_   | | |  _
//  | |___ |_| | |\  |  _|  | | |_| |
//   \____\___/|_| \_|_|   |___\____|
//
axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL
axiosInterceptors()
Vue.use(VueAxios, axios)

routingInterceptors()

// VeeValidate
Vue.component('ValidationProvider', VeeValidationProvider)
Vue.component('ValidationObserver', VeeValidationObserver)
VeeExtend('required', VeeRequired)
VeeExtend('email', VeeEmail)
VeeExtend('min', VeeMin)
VeeExtend('confirmed', VeeConfirmed)

Vue.use(CoreuiVue)
Vue.use(require('vue-moment'), {
  moment
})
Vue.use(VuejsDialog)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(sNotify)
Vue.use(Vue2Filters)
Vue.use(VueInputMask)
Vue.use(VCalendar)
Vue.use(CoreuiVueCharts)
Vue.use(VueAuthImage)
Vue.use(VueSignaturePad)

Vue.component('v-select', vSelect)
Vue.component('vue-repeater', VueRepeater)
Vue.component('formular-material', FormularMaterial)
Vue.component('formular-arbeitszeit', FormularArbeitszeit)

//  __     ___   _ _____
//  \ \   / / | | | ____|
//   \ \ / /| | | |  _|
//    \ V / | |_| | |___
//     \_/   \___/|_____|
//
Vue.config.productionTip = false
Vue.prototype.$roleBasedSecurity = RoleBasedSecurity
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  icons,
  render: h => h(App)
}).$mount('#app')

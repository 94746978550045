import {
  cilAddressBook,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilBan,
  cilBank,
  cilBeachAccess,
  cilBellExclamation,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilChart,
  cilChartLine,
  cilCloudDownload,
  cilCoffee,
  cilDescription,
  cilFile,
  cilFilter,
  cilEnvelopeClosed,
  cilEuro,
  cilFingerprint,
  cilHighlighter,
  cilHttps,
  cilImage,
  cilLan,
  cilLibraryAdd,
  cilLockLocked,
  cilLockUnlocked,
  cilMagnifyingGlass,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMoon,
  cilPaperclip,
  cilPeople,
  cilPrint,
  cilSettings,
  cilShare,
  cilShieldAlt,
  cilSpeedometer,
  cilStorage,
  cilSun,
  cilTerminal,
  cilText,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilUser,
  cilUserPlus,
  cilWarning,
  cilWindow
} from '@coreui/icons'

export const iconsSet = Object.assign({}, {
  cilAddressBook,
  cilArrowThickLeft,
  cilArrowThickRight,
  cilArrowThickToLeft,
  cilArrowThickToRight,
  cilBan,
  cilBank,
  cilBeachAccess,
  cilBellExclamation,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCart,
  cilChart,
  cilChartLine,
  cilCloudDownload,
  cilCoffee,
  cilDescription,
  cilFile,
  cilFilter,
  cilEnvelopeClosed,
  cilEuro,
  cilFingerprint,
  cilHighlighter,
  cilHttps,
  cilImage,
  cilLan,
  cilLibraryAdd,
  cilLockLocked,
  cilLockUnlocked,
  cilMagnifyingGlass,
  cilMediaSkipBackward,
  cilMediaSkipForward,
  cilMoon,
  cilPaperclip,
  cilPeople,
  cilPrint,
  cilSettings,
  cilShare,
  cilShieldAlt,
  cilSpeedometer,
  cilStorage,
  cilSun,
  cilTerminal,
  cilText,
  cilThumbDown,
  cilThumbUp,
  cilTrash,
  cilUser,
  cilUserPlus,
  cilWarning,
  cilWindow
})

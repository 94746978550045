import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views Security
const Login = () => import('@/views/Login')
const Logout = () => import('@/views/Logout')
const Password = () => import('@/views/Password')

// Eigenes Profil
const ProfilePassword = () => import('@/views/Profile/Password')

// Protokoll
const ProtokollIndex = () => import('@/views/Protokoll/Index')
const ProtokollPrint = () => import('@/views/Protokoll/Print')

// Mitarbeiter
const MitarbeiterIndex = () => import('@/views/Mitarbeiter/Index')
const MitarbeiterNeu = () => import('@/views/Mitarbeiter/Neu')
const MitarbeiterEdit = () => import('@/views/Mitarbeiter/Edit')
const MitarbeiterPasswort = () => import('@/views/Mitarbeiter/Passwort')
const MitarbeiterRollen = () => import('@/views/Mitarbeiter/Rollen')

// Freizeit
const FreizeitIndex = () => import('@/views/Freizeit/Index')
const FreizeitNeu = () => import('@/views/Freizeit/Neu')
const FreizeitEdit = () => import('@/views/Freizeit/Edit')

// Leistungen
const LeistungenIndex = () => import('@/views/Leistungen/Index')
const LeistungenNeu = () => import('@/views/Leistungen/Neu')
const LeistungenEdit = () => import('@/views/Leistungen/Edit')

// Leistungen
const TextbausteineIndex = () => import('@/views/Textbausteine/Index')
const TextbausteineNeu = () => import('@/views/Textbausteine/Neu')
const TextbausteineEdit = () => import('@/views/Textbausteine/Edit')

// Abwesenheiten
const AbwesenheitenIndex = () => import('@/views/Abwesenheit/Index')
const AbwesenheitenNeu = () => import('@/views/Abwesenheit/Neu')
const AbwesenheitenEdit = () => import('@/views/Abwesenheit/Edit')

// Kunden
const KundenIndex = () => import('@/views/Kunden/Index')
const KundenNeu = () => import('@/views/Kunden/Neu')
const KundenEdit = () => import('@/views/Kunden/Edit')

// Projekte beim Kunden
const ProjekteIndex = () => import('@/views/Projekte/Index')
const ProjekteNeu = () => import('@/views/Projekte/Neu')
const ProjekteEdit = () => import('@/views/Projekte/Edit')

// Tageskalender
const TagesKalenderIndex = () => import('@/views/Tageskalender/Index')
const TagesKalenderPrint = () => import('@/views/Tageskalender/Print')
const TagesKalenderSuche = () => import('@/views/Tageskalender/Suche')
const TagesKalenderCopy = () => import('@/views/Tageskalender/Copy')

// Report
const ReportIndex = () => import('@/views/Report/Index')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/security',
      redirect: '/security/login',
      name: 'Security',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'logout',
          name: 'Logout',
          component: Logout
        },
        {
          path: 'password',
          name: 'Password',
          component: Password
        }
      ]
    },
    {
      path: '/profile',
      redirect: '/profile/password',
      name: 'Profil',
      component: TheContainer,
      children: [
        {
          path: 'password',
          name: 'Mein Passwort Ändern',
          component: ProfilePassword,
          meta: { requiresAuth: true, sidebarMenu: 'profile' }
        }
      ]
    },
    {
      path: '/mitarbeiter',
      redirect: '/mitarbeiter/uebersicht',
      name: 'Mitarbeiter',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Mitarbeiter Übersicht',
          component: MitarbeiterIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
        },
        {
          path: 'neu',
          name: 'Mitarbeiter Anlegen',
          component: MitarbeiterNeu,
          meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Mitarbeiter Editieren',
          component: MitarbeiterEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
        },
        {
          path: 'passwort/:uuid',
          name: 'Mitarbeiter Passwort ändern',
          component: MitarbeiterPasswort,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
        },
        {
          path: 'rollen',
          name: 'Mitarbeiter Rollen',
          component: MitarbeiterRollen,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_ADMIN'] }
        }
      ]
    },
    {
      path: '/freizeit',
      redirect: '/freizeit/uebersicht',
      name: 'Freizeit',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Freizeit Übersicht',
          component: FreizeitIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'neu',
          name: 'Neue Freizeit Anlegen',
          component: FreizeitNeu,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Freizeit Editieren',
          component: FreizeitEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/leistungen',
      redirect: '/leistungen/uebersicht',
      name: 'Leistungen',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Leistungen Übersicht',
          component: LeistungenIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'neu',
          name: 'Neue Leistung Anlegen',
          component: LeistungenNeu,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Leistung Editieren',
          component: LeistungenEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/textbausteine',
      redirect: '/textbausteine/uebersicht',
      name: 'Textbausteine',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Textbausteine Übersicht',
          component: TextbausteineIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'neu',
          name: 'Neuen Textbaustein Anlegen',
          component: TextbausteineNeu,
          meta: { requiresAuth: true, allowed: ['ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Textbausteine Editieren',
          component: TextbausteineEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/abwesenheiten',
      redirect: '/abwesenheiten/uebersicht',
      name: 'Abwesenheiten',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht/:uuid?',
          name: 'Abwesenheiten Übersicht',
          component: AbwesenheitenIndex,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'neu/:uuid?',
          name: 'Neue Abwesenheit Anlegen',
          component: AbwesenheitenNeu,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Abwesenheit Editieren',
          component: AbwesenheitenEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/kunden',
      redirect: '/kunden/uebersicht',
      name: 'Kunden',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Kunden Übersicht',
          component: KundenIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'neu',
          name: 'Neuen Kunden Anlegen',
          component: KundenNeu,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Kunde Editieren',
          component: KundenEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/projekte',
      redirect: '/projekte/uebersicht',
      name: 'Projekte',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht/:uuid?',
          name: 'Projekte Übersicht',
          component: ProjekteIndex,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'neu/:uuid?',
          name: 'Neues Projekt Anlegen',
          component: ProjekteNeu,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'editieren/:uuid',
          name: 'Projekt Editieren',
          component: ProjekteEdit,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/',
      name: 'Protokoll',
      redirect: '/tageskalender',
      component: TheContainer,
      children: [
        {
          path: 'protokoll',
          name: 'Protokoll',
          component: ProtokollIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'print/:uuid?',
          name: 'Druckansicht Protokoll',
          component: ProtokollPrint,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/tageskalender',
      redirect: '/tageskalender/uebersicht',
      name: 'Tageskalender',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht/:pdatum?',
          name: 'Tageskalender Übersicht',
          component: TagesKalenderIndex,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'print/:year?/:week?',
          name: 'Tageskalender KW Ausdrucken',
          component: TagesKalenderPrint,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'suche/:suchwort?',
          name: 'Tageskalender Suche',
          component: TagesKalenderSuche,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        },
        {
          path: 'copy/:tageskalenderid?',
          name: 'Tageskalender in Protokoll umwandeln',
          component: TagesKalenderCopy,
          props: true,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    },
    {
      path: '/report',
      redirect: '/report/uebersicht',
      name: 'Report',
      component: TheContainer,
      children: [
        {
          path: 'uebersicht',
          name: 'Report Übersicht',
          component: ReportIndex,
          meta: { requiresAuth: true, allowed: ['ROLE_KALENDER', 'ROLE_TEAM', 'ROLE_REPORT'] }
        }
      ]
    }
  ]
})

/**
 * WENN nicht authentifiziert, dann sofort Redirect auf das Login
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['security/isAuthenticated']) {
      next()
    } else {
      next({ name: 'Login' })
    }
  } else {
    next()
  }
})

export default router

//   ____  _____ ____  ____ ___ ____ _____ _____ _   _ _____
//  |  _ \| ____|  _ \/ ___|_ _/ ___|_   _| ____| \ | |_   _|
//  | |_) |  _| | |_) \___ \| |\___ \ | | |  _| |  \| | | |
//  |  __/| |___|  _ < ___) | | ___) || | | |___| |\  | | |
//  |_|   |_____|_| \_\____/___|____/ |_| |_____|_| \_| |_|
//

export default {
  namespaced: true,
  state: {
    profimodus: localStorage.getItem('profimodus') || false
  },
  getters: {
    getProfimodus: (state) => {
      let profimodus = state.profimodus
      if (typeof profimodus === 'string') {
        if (profimodus === 'true') {
          profimodus = true
        } else {
          profimodus = false
        }
      }
      return profimodus
    }
  },
  mutations: {
    SET_PROFIMODUS: function (state, modus) {
      state.profimodus = modus
      localStorage.setItem('profimodus', modus)
    }
  },
  actions: {
  }
}

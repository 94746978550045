<template>
  <CRow class="form-group">
    <CCol sm="12">
      <ValidationProvider :rules="rules" immediate v-slot="{ errors }">
        <label :class="formLabelClass">{{formLabel}}</label>
        <v-date-picker v-model="myDate" :input-props='{ class: calculatedClass(errors) }' v-on:input="inputEvent">

          <template v-slot="{ inputValue, inputEvents }">
            <CInput
              :value="inputValue"
              v-on="inputEvents"
              :isValid="errors.length ==  0"
              :readonly="true"
            >
              <template #prepend-content><CIcon name="cil-calendar"/></template>

              <template #append-content>
                <span v-on:click="emptyDate" class="emptyDate">
                  <CIcon name="cil-trash" />
                </span>
              </template>

              <template #invalid-feedback>
                <em class="error invalid-feedback">Das Feld darf nicht leer sein.</em>
              </template>
            </CInput>
          </template>

        </v-date-picker>

      </ValidationProvider>
    </CCol>
  </CRow>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FormDate',
  props: {
    formLabel: {
      default: 'Text',
      type: String
    },
    formLabelClass: {
      default: '',
      type: String
    },
    rules: {
      default: 'required',
      type: String
    },
    value: [String, Date]
  },
  data () {
    return {
      myDate: null
    }
  },
  mounted () {
    this.valueToContent()
  },
  watch: {
    value: function () {
      this.valueToContent()
    }
  },
  methods: {
    emptyDate: function () {
      this.myDate = null
      this.$emit('input', null)
    },
    inputEvent: function (newValue) {
      if (newValue && newValue !== '') {
        this.$emit('input', moment(newValue).format('DD.MM.YYYY'))
      } else {
        this.$emit('input', '')
      }
    },
    calculatedClass: function (errors) {
      let result = 'form-control'
      if (errors.length === 0) {
        result = result + ' is-valid'
      } else {
        result = result + ' is-invalid'
      }
      return result
    },
    valueToContent () {
      if (this.value) {
        if (typeof this.value === 'string' && this.value.length > 0) {
          // Falls Deutsches Datumsformat, dann konvertieren
          if (this.value.indexOf('.') !== -1) {
            this.myDate = moment(this.value, 'DD.MM.YYYY').toDate()
          } else {
            this.myDate = moment(this.value).toDate()
          }
          // Workaround um bei erstamliger Nutzung die Uhrzeit zu entfernen
          this.inputEvent(this.myDate)
          //
        } else {
          this.myDate = this.value
        }
      } else {
        /**
         * Wenn kein Datum übergeben wurd (leer), aktutelles Datum verwenden,
         * da ansonsten Fehler im Input geschmissen werden
         */
        // this.myDate = new Date()
        // this.$emit('input', moment(this.myDate).format('DD.MM.YYYY'))
        this.myDate = ''
        this.inputEvent('')
      }
    }
  }
}
</script>

<style lang="scss">
span.emptyDate {
  cursor: pointer;
}
</style>
